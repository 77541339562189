import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'sales',
      category: 'sales',
      default: true,
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/sales',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/sales',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          breadcrumbHelpEnabled: true,
          roles: undefined
        }
      ]
    },
    {
      id: 'website',
      default: true,
      category: 'website',
      defaultRoute: '/website/overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'scorecard',
          displayName: 'Scorecard',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'referrer',
          displayName: 'Referrer',
          iconClass: 'fa fa-share',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'provider',
          displayName: 'Provider',
          iconClass: 'fa fa-cogs',
          roles: ['System Administrator', 'Corporate']
        },
      ]
    },
    {
      id: 'digAd',
      category: 'digAd',
      default: true,
      defaultRoute: '/digAd/overview',
      displayName: 'Digital Advertising',
      displayInNav: true,
      iconClass: 'fa fa-desktop',
      roles: undefined,
      children: [
        {
          parent: '/digAd',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'scorecard',
          displayName: 'Scorecard',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'summary',
          displayName: 'Summary',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'provider',
          displayName: 'Provider',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-cogs',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'channel',
          displayName: 'Channel',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-cogs',
          roles: undefined
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator']
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator']
        }
      ]
    }
  ];
}
